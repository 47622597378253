import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { isInternalLink } from "src/utils";
import nprogress from "nprogress";

const sharedStyled = css`
  text-decoration: none;
`;

const StyledGatsbyLink = styled(GatsbyLink)`
  ${sharedStyled};
`;

const StyledLink = styled.a`
  ${sharedStyled};
`;

const onGatsbyLinkClick = currentPath =>
  currentPath !== window.location.pathname && !currentPath.startsWith("/#") && nprogress.start();

const Link = ({ children, className, to, ...rest }) => {
  if (isInternalLink(to)) {
    return (
      <StyledGatsbyLink className={className} to={to} onClick={() => onGatsbyLinkClick(to)} {...rest}>
        {children}
      </StyledGatsbyLink>
    );
  }

  return (
    <StyledLink className={className} href={to} {...rest}>
      {children}
    </StyledLink>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default Link;
