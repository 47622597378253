import { internalPathRegex } from "src/constants";
import last from "lodash/last";

const emailRegexp = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const bytesToSize = bytes => {
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  const i = bytes === 0 ? 0 : Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024));

  if (i === 0) {
    return bytes + " " + sizes[i];
  }

  if (i === -1) {
    return bytes.toFixed(2) + " " + sizes[0];
  }

  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
};

export const replaceHttpSrcWithHttps = html => {
  const pattern = new RegExp(`src="http://`, "g");
  const replacement = `src="https://`;

  return html.replace(pattern, replacement);
};

export const replaceHttpWithHttps = link => {
  return link.replace("http://", "https://");
};

export const isInternalLink = link => internalPathRegex.test(link);

export const openCrisp = () => {
  if (window.$crisp) {
    window.$crisp.push(["do", "chat:open"]);
  }
};

export const getRetinaPath = path => {
  const ext = `.${last(path.split("."))}`;
  return `${path.replace(ext, "")}@2x${ext}`;
};

export const validateEmail = email => {
  let validationResult = {
    isValid: false,
    fieldName: "email",
  };

  if (!email) {
    return { ...validationResult, error: "Required" };
  } else if (email && !emailRegexp.test(email)) {
    return { ...validationResult, error: "Invalid email address" };
  }

  return { ...validationResult, isValid: true };
};

export const validateOkay = (name) => {
  return {
    isValid: true,
    fieldName: name,
  };
}

export const validateCommonName = (name, fieldName) => {
  let validationResult = {
    isValid: false,
    fieldName: fieldName,
  };

  if (!name || (name && name.trim().length < 1)) {
    return { ...validationResult, error: "Required" };
  }

  return { ...validationResult, isValid: true };
}


export const validateRole = name => {
  return validateCommonName(name, "role")
};

export const validateFirstName = name => {
  return validateCommonName(name, "firstName")
};

export const validateLastName = name => {
  return validateCommonName(name, "lastName")
};

export const validateVip = name => {
  return validateCommonName(name, "vipName")
};

export const validatePassword = password => {
  let validationResult = {
    isValid: false,
    fieldName: "password",
  };

  if (!password) {
    return { ...validationResult, error: "Required" };
  }

  if (password && password.length < 6) {
    return { ...validationResult, error: "Password is too short" };
  }

  if (password && /\s/.test(password)) {
    return { ...validationResult, error: "Password should not contain spaces" };
  }

  return { ...validationResult, isValid: true };
};

export const validatePrivacy = privacy => {
  let validationResult = {
    isValid: false,
    fieldName: "privacy",
  };

  if (!privacy) {
    return { ...validationResult, error: "Privacy Policy & Terms of Use should be accepted" };
  }

  return { ...validationResult, isValid: true };
};

export const trackSignup = method => {
  if (process.env.GATSBY_ENABLE_ADS_TRACKING === "true") {
    window.heap && window.heap.track("Landing Sign Up", { method });
    window.twq && window.twq("track", "Sign up", { method });
    window.gtag && window.gtag("event", "sign_up", { method });
  }
};
