import React from "react";
import PropTypes from "prop-types";
import { getRetinaPath } from "src/utils";

const Image = ({ className, path, ...rest }) => (
  <img
    className={`${className} lazyload`}
    data-src={path}
    data-srcset={`${path} 1x, ${getRetinaPath(path)} 2x`}
    {...rest}
  />
);

Image.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string.isRequired,
};

export default Image;
