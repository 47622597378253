import styled from "styled-components";
import { mediaLg, mediaMd, mediaSm } from "src/theme/utils";
import { sizes } from "src/theme/variables";

export const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1264px;
  padding-left: ${sizes.gutter / 2}px;
  padding-right: ${sizes.gutter / 2}px;

  ${mediaLg`
    max-width: none;
    width: auto;
    margin-left: ${50 - sizes.gutter / 2}px;
    margin-right: ${50 - sizes.gutter / 2}px;
  `}

  ${mediaMd`
    margin-left: ${24 - sizes.gutter / 2}px;
    margin-right: ${24 - sizes.gutter / 2}px;
  `}

  ${mediaSm`
    margin-left: ${20 - sizes.gutter / 2}px;
    margin-right: ${20 - sizes.gutter / 2}px;
  `}
`;

export const Row = styled.div`
  margin-left: -${sizes.gutter / 2}px;
  margin-right: -${sizes.gutter / 2}px;
`;

export const Column = styled.div`
  width: ${({ xl, grid }) => xl && `${(100 / (grid?.xl || sizes.gridXl)) * xl}%`};
  padding-left: ${sizes.gutter / 2}px;
  padding-right: ${sizes.gutter / 2}px;
  margin-left: ${({ space, grid }) => `${(100 / (grid?.xl || sizes.gridXl)) * (space?.xl || 0)}%`};

  ${mediaLg`
    width: ${({ lg, grid }) => lg && `${(100 / (grid?.lg || sizes.gridLg)) * lg}%`};
    margin-left: ${({ space, grid }) => `${(100 / (grid?.lg || sizes.gridLg)) * (space?.lg || 0)}%`};
  `}

  ${mediaMd`
    width: ${({ md, grid }) => md && `${(100 / (grid?.md || sizes.gridMd)) * md}%`};
    margin-left: ${({ space, grid }) => `${(100 / (grid?.md || sizes.gridMd)) * (space?.md || 0)}%`};
  `}

  ${mediaSm`
    width: ${({ sm, grid }) => sm && `${(100 / (grid?.sm || sizes.gridSm)) * sm}%`};
    margin-left: ${({ space, grid }) => `${(100 / (grid?.sm || sizes.gridSm)) * (space?.sm || 0)}%`};
  `}
`;
