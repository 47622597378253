import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import Img from "gatsby-image";
import SVGOvalWite from "../svg/images/oval-white.svg";
import theme from "src/components/theme";

export const Link = ({ href, ...otherProps }) => {
  const internal = /^\/(?!\/)/.test(href);

  if (internal) {
    return <GatsbyLink to={href} {...otherProps} activeClassName="link-active" />;
  } else {
    // eslint-disable-next-line
    return <a href={href} {...otherProps} />;
  }
};

export const TextLink = styled(Link)`
  background-color: transparent;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
`;

export const AccentLinkButton = styled(Link)`
  background-color: ${theme.accentColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0 5px 15px 0 rgba(249, 97, 123, 0.2);
  cursor: pointer;

  @media (min-width: ${theme.mobileBreakpoint}) {
    font-size: 18px;
  }

  &:hover {
    background-color: #e65a71;
  }
`;

export const TitleLinkButton = styled(AccentLinkButton)`
  color: ${theme.accentColor};
  background-color: ${theme.titleColor};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: #ebebeb;
  }
`;

export const H1 = styled.h1`
  font-size: 48px;
  font-weight: 700;
  line-height: 1;

  @media (min-width: ${theme.mobileBreakpoint}) {
    font-size: 72px;
  }
`;

export const StyledSVGOval = styled.svg.attrs({
  viewBox: SVGOvalWite.viewBox,
})`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 0;
  color: #f4f8fb !important;

  @media (min-width: ${theme.mobileBreakpoint}) {
    height: 85px;
  }
`;

export const H2 = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.17;
  letter-spacing: 0.9px;
  color: ${theme.titleColor};

  @media (min-width: ${theme.mobileBreakpoint}) {
    font-size: 36px;
  }
`;

export const H3 = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.6px;
  color: ${theme.titleColor};

  @media (min-width: ${theme.mobileBreakpoint}) {
    font-size: 24px;
  }
`;

export const Text = styled.span`
  line-height: 1.33;
  color: ${theme.textColor};
`;

export const CopyrightLink = styled(Link)`
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.4px;
  font-weight: bold;
  color: ${theme.titleColor};
`;

const ContentDisplay = styled.div`
  font-size: 18px;
  line-height: 1.44;
  color: ${theme.titleColor};

  h2 {
    margin: 80px 0 16px 0;
  }

  a {
    font-weight: bold;
    color: ${theme.accentColor};
  }
`;

export const DuckImage = styled(Img)`
  width: 100%;
  max-width: 329px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    margin-left: 27px;
  }
`;

// Component is used to display both rendered html from remark transformer, and markdown widget from netlify cms
export const MarkdownContent = ({ content, ...props }) => {
  if (React.isValidElement(content)) {
    return <ContentDisplay {...props}>{content}</ContentDisplay>;
  } else {
    return <ContentDisplay dangerouslySetInnerHTML={{ __html: content }} {...props} />;
  }
};
