import React from "react";
import Header from "src/elements/Header";
import GlobalStyles from "./GlobalStyles";
import NormalizeStyles from "./NormalizeStyles";
import CookiesNotice from "./CookiesNotice";
import PropTypes from "prop-types";
import ReactHelmet from "react-helmet";
import { WebsiteMeta } from "./meta/WebsiteMeta";
import MobileMenu from "src/elements/MobileMenu";
import DynamicHeader from "src/elements/DynamicHeader";
import Footer from "src/elements/Footer";

// Track only on prod
const shouldTrackAds = process.env.GATSBY_ENABLE_ADS_TRACKING === "true";

const AdsTrackingHelm = () => (
  <ReactHelmet>
    <link rel="preconnect" href="https://app.appspector.com" />
    {/* Google Ads Tag */}
    <script async src="https://app.appspector.com/api/proxy/h/gat/gtag/js?id=G-SETYPGZYVT" />
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-SETYPGZYVT');
      `}
    </script>
    {/* Twitter Tag */}
    <script>
      {`
        !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://app.appspector.com/api/proxy/h/twi/uwt.js',
        a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
        // Insert Twitter Pixel ID and Standard Event data below
        twq('init','o0au4');
        twq('track','PageView');
      `}
    </script>
    {/* HubSpot Tag */}
    <script async defer id="hs-script-loader" src="https://app.appspector.com/api/proxy/h/hub/21957844.js" />
  </ReactHelmet>
);

const SCROLL_BREAKPOINTS = [25, 50, 75, 100];
const GOOGLE_SCROLL_EVENT_IDS = [
  "AW-762540326/qHtWCLeyy5UBEKbizesC",
  "AW-762540326/0iq4CM3Iy5UBEKbizesC",
  "AW-762540326/MeKUCLiZxZUBEKbizesC",
  "AW-762540326/-imOCL7-t5UBEKbizesC"
];

class ScrollTrackingComponent extends React.Component {
  scrollBreakpointsReached = SCROLL_BREAKPOINTS.map(() => false);

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return null;
  }

  handleScroll = () => {
    if (window.heap) {
      const scrollPercentage =
        ((document.documentElement.scrollTop + document.body.scrollTop) /
          (document.documentElement.scrollHeight - document.documentElement.clientHeight) || 0) * 100;

      SCROLL_BREAKPOINTS.forEach((bp, index) => {
        if (!this.scrollBreakpointsReached[index] && scrollPercentage >= bp) {
          this.scrollBreakpointsReached[index] = true;
          window.heap.track("Scroll Depth", { percent: bp });

          const gtag = global.gtag;
          if (shouldTrackAds && GOOGLE_SCROLL_EVENT_IDS[index] && typeof gtag === "function") {
            gtag("event", "conversion", { send_to: GOOGLE_SCROLL_EVENT_IDS[index] });
          }
        }
      });
    }
  };
}

const fontsCss = () => {
  return <style>
    {`
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('DM Sans'), url(/fonts/dm-sans-v6-latin-regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('DM Sans'), url(/fonts/dm-sans-v6-latin-500.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('DM Sans'), url(/fonts/dm-sans-v6-latin-700.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
        `}
  </style>;
}

const Layout = ({ children, header, skipMeta, footer }) => (
  <>
    <ReactHelmet>
      <link rel="preconnect" href="https://use.typekit.net" crossOrigin as="font" />
      <link rel="preconnect" href="https://p.typekit.net" crossOrigin as="font" />
      <link href="https://use.typekit.net/qvq2iif.css" rel="stylesheet" />
      <link rel="preload" as="font" type="font/woff2"
        href="/fonts/dm-sans-v6-latin-regular.woff2" crossOrigin />
      <link rel="preload" as="font" type="font/woff2"
        href="/fonts/dm-sans-v6-latin-500.woff2" crossOrigin />
      <link rel="preload" as="font" type="font/woff2"
        href="/fonts/dm-sans-v6-latin-700.woff2" crossOrigin />
      {fontsCss()}
    </ReactHelmet>
    {skipMeta ? null : <WebsiteMeta />}
    {shouldTrackAds && <AdsTrackingHelm />}
    <GlobalStyles />
    <NormalizeStyles />
    {header}
    {children}
    {footer}
    <ScrollTrackingComponent />

    {/* FIXME: Need to investigate className merging in production build without additional unique div wrappers */}
    <div id="CookiesNotice">
      <CookiesNotice />
    </div>

    <div id="MobileMenu">
      <MobileMenu />
    </div>

    <div id="DynamicHeader">
      <DynamicHeader />
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  skipMeta: PropTypes.bool
};

Layout.defaultProps = {
  header: <Header />,
  footer: <Footer />
};

export default Layout;
