import React from "react";
import PropTypes from "prop-types";

const Svg = ({ className, path, ...rest }) => {
  const svg = require(`src/svg/${path}.svg`);
  return (
    <svg className={className} viewBox={svg.viewBox} {...rest}>
      <use xlinkHref={`#${svg.id}`} />
    </svg>
  );
};

Svg.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string.isRequired,
};

export default Svg;
