import { css } from "styled-components";
import { breakpoints } from "./variables";

const vw = (px, breakpoint) => `${(px / breakpoint) * 100}vw`;

export const vwLg = px => vw(px, breakpoints.lg);
export const vwMd = px => vw(px, breakpoints.md);
export const vwSm = px => vw(px, breakpoints.sm);

const media = breakpoint => (...args) => css`
  @media (max-width: ${breakpoint - 1}px) {
    ${css(...args)};
  }
`;

export const mediaLg = media(breakpoints.lg);
export const mediaMd = media(breakpoints.md);
export const mediaSm = media(breakpoints.sm);
export const mediaDemo = media(breakpoints.demo);

export const transition = prop => {
  const params = "0.2s ease-out";
  return prop.map
    ? css`
        ${prop.map(p => `${p} ${params}`).join(",")}
      `
    : css`
        ${prop} ${params}
      `;
};
