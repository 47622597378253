import * as React from "react";
import SocialImagePath from "../../images/social-image.png";
import ReactHelmet from "react-helmet";

export const WebsiteMeta = () => {
  return (
    <ReactHelmet
      titleTemplate="AppSpector | %s"
      defaultTitle="AppSpector | Remote debugging platform for iOS and Android apps."
    >
      <meta charset="utf-8" />

      <meta
        name="description"
        content="AppSpector is a service for debugging iOS and Android apps. Visualize, inspect, and control your apps remotely in real-time from a simple interface."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta property="og:title" content="AppSpector: Remote debugging for mobile apps" />
      <meta property="og:description" content="The Future of mobile app debugging is here." />
      <meta property="og:site_name" content="AppSpector" />
      <meta property="og:image" content={`https://appspector.com${SocialImagePath}`} />
      <meta property="og:url" content="https://appspector.com/" />
      <meta property="og:type" content="website" />
      <meta name="twitter:title" content="AppSpector: Remote debugging for mobile apps" />
      <meta name="twitter:description" content="The Future of mobile app debugging is here." />
      <meta name="twitter:image" content={`https://appspector.com${SocialImagePath}`} />
      <meta name="twitter:url" content="https://appspector.com/" />
      <meta name="twitter:site" content="@appspector" />
      <meta name="twitter:card" content="summary_large_image" />
    </ReactHelmet>
  );
};
