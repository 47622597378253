import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { mediaSm, transition } from "src/theme/utils";
import { Typography, Link, Svg } from "src/theme/elements";
import { colors } from "src/theme/variables";

const StyledRoot = styled(Link)`
  display: flex;
  align-items: center;
  width: max-content;
  color: ${colors.crimson};
`;

const StyledText = styled(Typography)`
  color: inherit;
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;

  ${mediaSm`
    font-size: 18px;
  `}
`;

const StyledIcon = styled(Svg).attrs({
  path: "icons/arrow-right",
})`
  height: 22px;
  margin-left: 16px;
  transition: ${transition("transform")};
  will-change: transform;

  ${mediaSm`
    margin-left: 12px;
  `}

  ${StyledRoot}:hover & {
    transform: translateX(4px);

    ${mediaSm`
      transform: translateX(0);
    `}
  }
`;

const ThemeLink = ({ className, children, to, ...rest }) => (
  <StyledRoot className={className} to={to} {...rest}>
    <StyledText>{children}</StyledText>
    <StyledIcon />
  </StyledRoot>
);

ThemeLink.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default ThemeLink;
