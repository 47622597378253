import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Link } from "./Common";
import { zIndex } from "src/theme/variables";
import { mediaSm } from "src/theme/utils";
import { Container } from "src/theme/layout";
import theme from "src/components/theme";

const NoticeContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: ${theme.colors.blackish};
  z-index: ${zIndex.cookiesNotice};
`;

const StyledContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;

  ${mediaSm`
    flex-flow: column nowrap;
  `}
`;

const NoticeText = styled.div`
  max-width: 820px;
  flex: initial;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.titleColor};
  margin-right: 24px;

  ${mediaSm`
    margin-right: 0;
    margin-bottom: 24px;
  `}
`;

const PrivacyLink = styled(Link)`
  display: inline;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 400;
  cursor: pointer;
`;

const AcceptButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 144px;
  height: 48px;
  font-size: 18px;
  border-radius: 4px;
  background-color: ${theme.colors.lightGray};
  color: ${theme.colors.blackish};
  cursor: pointer;
`;

const cookieConcentKey = "user-has-read-cookie-notice";

const CrispShiftStyle = createGlobalStyle`
  #crisp-chatbox div > a {
    bottom: 102px ${p => (p.important ? "!important" : "")};
    @media screen and (max-width: ${theme.mobileBreakpoint}) {
      bottom: 213px ${p => (p.important ? "!important" : "")};
    }
  }
`;

export default class CookiesNotice extends React.Component {
  render() {
    const isConcentReceived = this.isConcentReceived();
    return (
      <>
        <CrispShiftStyle important={!isConcentReceived} />
        {!isConcentReceived && (
          <NoticeContainer>
            <StyledContainer>
              <NoticeText>
                This website uses cookies and other technology to customize advertising and provide you a more
                personalized experience. To find out more, see our{" "}
                <PrivacyLink href="/privacy-policy">Privacy Policy</PrivacyLink>.
              </NoticeText>
              <AcceptButton onClick={this.acceptClicked.bind(this)}>OK</AcceptButton>
            </StyledContainer>
          </NoticeContainer>
        )}
      </>
    );
  }

  isConcentReceived() {
    if (typeof window !== "undefined") {
      return window.document.cookie.includes(`${cookieConcentKey}=`);
    }

    // HACK: returns 'true' by default cause we don't want to display cookie notice in case window is not yet available
    // The reason is Gatsby renders page on the server side when window is not yet available
    return true;
  }

  acceptClicked() {
    const host = window.location.hostname
      .split(".")
      .slice(-2)
      .join(".");
    window.document.cookie = `${cookieConcentKey}=true;domain=${host};path=/;expires=Fri, 31 Dec 2029 23:59:59 GMT`;
    this.forceUpdate();
  }
}
