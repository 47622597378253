import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { zIndex } from "src/theme/variables";
import { mediaMd, mediaLg, transition } from "src/theme/utils";
import Header from "./Header";
import debounce from "lodash/debounce";

const StyledRoot = styled.div.attrs({
  "data-dynamic-header": true,
})`
  position: fixed;
  z-index: ${zIndex.dynamicHeader};
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  will-change: opacity;
  transition: ${transition(["visibility", "opacity"])};

  ${({ isVisible }) =>
    isVisible &&
    `
      pointer-events: all;
      visibility: visible;
      opacity: 1;
    `};
`;

const StyledHeader = styled(Header)`
  padding-top: 16px;
  padding-bottom: 16px;

  ${mediaLg`
    padding-top: 12px;
    padding-bottom: 12px;
  `}

  ${mediaMd`
    padding-top: 10px;
    padding-bottom: 10px;
  `}
`;

const DynamicHeader = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    const darkSectionsElements = document.querySelectorAll("section[data-section-color='dark']");
    const headerElement = document.querySelector("div[data-dynamic-header]");
    const firstSection = document.querySelector("section[data-show-header]");

    const getDarkSectionsRanges = () =>
      Array.from(darkSectionsElements).reduce((acc, el) => {
        acc.push([el.offsetTop, el.offsetTop + el.offsetHeight]);
        return acc;
      }, []);

    const getHeaderHeight = () => headerElement.offsetHeight;

    const getShowAfter = () => (firstSection ? firstSection.offsetTop : window.innerHeight);

    let darkSectionsRanges = [];
    let headerHeight = 0;
    let showAfter = getShowAfter();

    const isMatchRange = scrollY => {
      for (let i = 0; i < darkSectionsRanges.length; i++) {
        if (
          scrollY > darkSectionsRanges[i][0] - headerHeight / 2 &&
          scrollY < darkSectionsRanges[i][1] - headerHeight / 2
        ) {
          return true;
        }
      }

      return false;
    };

    const handleScroll = () => {
      if (window.scrollY > showAfter - headerHeight / 2) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      if (darkSectionsRanges.length > 0 && isMatchRange(window.scrollY)) {
        setIsDark(true);
      } else {
        setIsDark(false);
      }
    };

    const handleResize = debounce(() => {
      darkSectionsRanges = getDarkSectionsRanges();
      headerHeight = getHeaderHeight();
      showAfter = getShowAfter();
    }, 200);

    // Initialization
    darkSectionsRanges = getDarkSectionsRanges();
    headerHeight = getHeaderHeight();
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <StyledRoot isVisible={isVisible} dark={isDark}>
      <StyledHeader variant={isDark ? "dark" : "light"} />
    </StyledRoot>
  );
};

export default DynamicHeader;
