import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { mediaLg, mediaSm, vwLg, vwSm, transition } from "src/theme/utils";
import { colors, fonts } from "src/theme/variables";

const getCommonStyle = (dark = false) => css`
  margin: 0;
  color: ${dark ? colors.blackish : colors.lightGray};
  font-family: ${fonts.primary}, Arial, sans-serif;
  transition: ${transition(["opacity", "visibility"])};
`;

const StyledH1 = styled.h1`
  ${({ settings }) => getCommonStyle(settings?.dark)}

  font-size: 70px;
  line-height: 84px;

  ${mediaLg`
    font-size: max(${vwLg(70)}, 59px);
    line-height: max(${vwLg(84)}, 72px);
  `}

  ${mediaSm`
    font-size: max(${vwSm(59)}, 40px);
    line-height: max(${vwSm(72)}, 52px);
  `}
`;

const StyledH2 = styled.h2`
  ${({ settings }) => getCommonStyle(settings?.dark)}

  font-size: 56px;
  line-height: 72px;
  letter-spacing: -0.9px;

  ${mediaLg`
    font-size: max(${vwLg(56)}, 50px);
    line-height: max(${vwLg(72)}, 64px);
  `}

  ${mediaSm`
    font-size: max(${vwSm(50)}, 32px);
    line-height: max(${vwSm(64)}, 40px);
  `}
`;

const StyledH3 = styled.h3`
  ${({ settings }) => getCommonStyle(settings?.dark)}

  font-size: 42px;
  line-height: 48px;
  letter-spacing: -0.5px;

  ${mediaLg`
    font-size: max(${vwLg(42)}, 38px);
    line-height: max(${vwLg(48)}, 48px);
  `}

  ${mediaSm`
    font-size: max(${vwSm(38)}, 28px);
    line-height: max(${vwSm(48)}, 38px);
  `}
`;

const StyledH4 = styled.h4`
  ${({ settings }) => getCommonStyle(settings?.dark)}

  font-size: 36px;
  line-height: 46px;

  ${mediaLg`
    font-size: max(${vwLg(36)}, 32px);
    line-height: max(${vwLg(46)}, 42px);
  `}

  ${mediaSm`
    font-size: max(${vwSm(32)}, 24px);
    line-height: max(${vwSm(42)}, 32px);
  `}
`;

const textStyle = css`
  font-size: 20px;
  line-height: 28px;

  ${mediaSm`
    font-size: 18px;
    line-height: 25px;
  `}
`;

const StyledH5 = styled.h5`
  ${({ settings }) => getCommonStyle(settings?.dark)}
  ${textStyle}
`;

const StyledH6 = styled.h6`
  ${({ settings }) => getCommonStyle(settings?.dark)}
  ${textStyle}
`;

const StyledP = styled.p`
  ${({ settings }) => getCommonStyle(settings?.dark)}
  ${textStyle}
`;

const StyledSpan = styled.span`
  ${({ settings }) => getCommonStyle(settings?.dark)}
  ${textStyle}
`;

const getTag = tag => {
  switch (tag) {
    case "h1":
      return StyledH1;
    case "h2":
      return StyledH2;
    case "h3":
      return StyledH3;
    case "h4":
      return StyledH4;
    case "h5":
      return StyledH5;
    case "h6":
      return StyledH6;
    case "p":
      return StyledP;
    default:
      return StyledSpan;
  }
};

const Typography = ({ children, className, settings, ...rest }) => {
  const StyledTag = getTag(settings?.tag);

  return (
    <StyledTag className={className} settings={settings} {...rest}>
      {children}
    </StyledTag>
  );
};

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  settings: PropTypes.shape({
    tag: PropTypes.string,
    dark: PropTypes.bool,
  }),
};

export default Typography;
