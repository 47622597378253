import React from "react";
import styled from "styled-components";
import { sizes, colors, fonts } from "src/theme/variables";
import { mediaSm, mediaLg, mediaMd, transition } from "src/theme/utils";
import { Container, Row, Column } from "src/theme/layout";
import { Typography, Link, Svg } from "src/theme/elements";
import { graphql, useStaticQuery } from "gatsby";

const StyledRoot = styled.footer`
  background-color: ${colors.white};
  overflow: hidden;

  svg {
    display: block;
  }
`;

const StyledTypography = styled(Typography)``;

const StyledContainer = styled(Container)`
  padding-top: 38px;
  padding-bottom: 22px;

  ${mediaSm`
    padding-top: 26px;
    padding-bottom: 18px;
  `}
`;

const StyledMainRow = styled(Row)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;

  ${mediaMd`
    flex-direction: column;
    margin-bottom: 6px;
  `}
`;

const StyledMenuContainer = styled(Column)`
  ${mediaMd`
    order: 2;
  `}
`;

const StyledMenuRow = styled(Row)`
  display: flex;
  align-items: flex-start;

  ${mediaSm`
    flex-direction: column;
  `}
`;

const StyledMenu = styled(Column)`
  padding-top: 5px;

  ${mediaSm`
    padding-top: 0;
    margin-bottom: 40px;
  `}

  &:nth-child(1) {
    margin-left: 0;
  }

  &:nth-child(2) {
    padding-left: 0;
    padding-right: 0;

    ${mediaSm`
      padding-left: ${sizes.gutter / 2}px;
      padding-right: ${sizes.gutter / 2}px;
    `}
  }
`;

const StyledMenuTitle = styled(Typography).attrs({
  settings: {
    tag: "h2",
    dark: true,
  },
})`
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const StyledLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: -7px;
  margin-right: -7px;
`;

const StyledMenuLink = styled(Link)`
  padding: 7px;

  ${StyledTypography} {
    font-size: 16px;
    line-height: 26px;
    font-family: ${fonts.secondary};
    font-weight: 400;
    transition: ${transition("color")};

    &:hover {
      color: #6f6f7d;
    }

    &:active {
      color: ${colors.darkGray};
    }
  }
`;

const StyledCopyright = styled(Typography)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  color: #929299;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  font-family: ${fonts.secondary};

  ${mediaSm`
    text-align: left;
  `}
`;

const StyledSocial = styled(Column)`
  ${mediaLg`
    max-width: 316px;
    margin-left: auto;
  `}

  ${mediaMd`
    margin-left: 0;
    order: 1;
    margin-bottom: 50px;
  `}
`;

const StyledSocialTitle = styled(Typography).attrs({
  settings: {
    tag: "h6",
    dark: true,
  },
})`
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const StyledSocialItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledSocialItem = styled.a`
  width: 38px;
  height: 38px;
  padding: 5px;
  color: ${colors.blackish};
  box-sizing: border-box;
  transition: ${transition("color")};

  &:hover {
    color: #6f6f7d;
  }

  &:active {
    color: ${colors.darkGray};
  }

  svg {
    height: 100%;
    margin: auto;
  }
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "//data/components/footer.md/" } }) {
        edges {
          node {
            frontmatter {
              copyright
              columns {
                title
                links {
                  title
                  href
                }
              }
            }
          }
        }
      }
    }
  `);

  const { columns, copyright } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <StyledRoot>
      <StyledContainer>
        <StyledMainRow>
          <StyledMenuContainer xl={9} lg={8} md={8}>
            <StyledMenuRow>
              {columns.map((column, index) => (
                <StyledMenu
                  xl={2}
                  lg={2}
                  md={2}
                  sm={4}
                  space={{ xl: 0.5, lg: 0.5, md: 0.5 }}
                  grid={{ xl: 9, lg: 8 }}
                  key={index}
                >
                  <StyledMenuTitle>{column.title}</StyledMenuTitle>
                  <StyledLinks>
                    {column.links.map((link, index) => (
                      <StyledMenuLink key={index} to={link.href}>
                        <StyledTypography settings={{ dark: true }}>{link.title}</StyledTypography>
                      </StyledMenuLink>
                    ))}
                  </StyledLinks>
                </StyledMenu>
              ))}
            </StyledMenuRow>
          </StyledMenuContainer>
          <StyledSocial xl={3} lg={4} md={3.5} sm={4} space={{ sm: 0 }}>
            <StyledSocialTitle>Get in touch</StyledSocialTitle>
            <StyledSocialItems>
              <StyledSocialItem href="https://github.com/appspector" target="_blank" rel="noopener">
                <Svg path="icons/github" />
              </StyledSocialItem>
              <StyledSocialItem href="https://slack.appspector.com/" target="_blank" rel="noopener">
                <Svg path="icons/slack" />
              </StyledSocialItem>
              <StyledSocialItem href="https://twitter.com/appspector" target="_blank" rel="noopener">
                <Svg path="icons/twitter" />
              </StyledSocialItem>
              <StyledSocialItem href="https://medium.com/appspector" target="_blank" rel="noopener">
                <Svg path="icons/medium" />
              </StyledSocialItem>
              <StyledSocialItem href="https://www.facebook.com/appspector" target="_blank" rel="noopener">
                <Svg path="icons/facebook" />
              </StyledSocialItem>
            </StyledSocialItems>
          </StyledSocial>
        </StyledMainRow>
        <StyledCopyright>
          {copyright}
        </StyledCopyright>
      </StyledContainer>
    </StyledRoot>
  );
};

export default Footer;
