import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { colors } from "src/theme/variables";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const StyledRoot = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  color: ${({ dark }) => (dark ? colors.blackish : colors.lightGray)};
`;

const StyledSpinner = styled.span`
  margin: auto;
  width: 40px;
  height: 40px;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
  border-color: currentColor;
  border-right-color: transparent;
  animation: ${spin} 0.7s linear infinite;
`;

const Loader = ({ className, dark = false }) => (
  <StyledRoot className={className} dark={dark}>
    <StyledSpinner />
  </StyledRoot>
);

Loader.propTypes = {
  className: PropTypes.string,
  dark: PropTypes.bool,
};

export default Loader;
