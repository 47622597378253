import React, { useRef } from "react";
import styled from "styled-components";
import { zIndex, colors } from "src/theme/variables";
import { mediaSm, transition } from "src/theme/utils";
import { Typography, Link, Button, Svg } from "src/theme/elements";
import { Container } from "src/theme/layout";

const StyledRoot = styled.div.attrs({
  "data-mobile-menu": true,
})`
  display: none;

  ${mediaSm`
    display: block;
    position: fixed;
    z-index: ${zIndex.mobileMenu};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: ${transition(["visibility", "opacity"])};

    &.isActive {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  `}
`;

const StyledTypography = styled(Typography)``;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 10px;
  padding-bottom: 27px;
`;

const StyledHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 74px;
`;

const StyledLogoContainer = styled(Link).attrs({
  to: "/",
})`
  display: flex;
  align-items: center;
`;

const StyledLogo = styled(Svg).attrs({
  path: "icons/appspector",
})`
  width: 32px;
  height: 32px;
  margin-right: 12px;
  color: ${colors.crimson};
`;

const StyledAppName = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.crimson};
`;

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: 60px;
`;

const StyledLink = styled(Link)`
  padding: 4px 6px;
  margin: 0 6px 24px;
  color: ${colors.blackish};
  transition: ${transition("color")};

  ${StyledTypography} {
    font-weight: 400;
    font-size: 15px;
    line-height: 1;
    color: inherit;
  }
`;

const StyledClose = styled.button`
  width: 44px;
  height: 44px;
  padding: 6px;
  margin-left: 6px;
  margin-right: -6px;
  background-color: transparent;
  color: ${colors.blackish};
`;

const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
  margin-top: auto;
`;

const StyledButton = styled(Button)`
  min-width: 256px;
  margin: 6px;
`;

const StyledHint = styled(Typography).attrs({
  settings: {
    tag: "p",
  },
})`
  color: ${colors.crimson};
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

const MobileMenu = () => {
  const rootRef = useRef(null);

  const closeMobileMenu = () => {
    if (rootRef && rootRef.current) {
      rootRef.current.classList.remove("isActive");
    }
  };

  return (
    <StyledRoot ref={rootRef}>
      <StyledContainer>
        <StyledHead>
          <StyledLogoContainer>
            <StyledLogo />
            <StyledAppName>AppSpector</StyledAppName>
          </StyledLogoContainer>
          <StyledClose aria-label="close mobile menu" onClick={closeMobileMenu}>
            <Svg path="icons/close" />
          </StyledClose>
        </StyledHead>
        <StyledMenu>
          <StyledLink to="/download">
            <StyledTypography>Download</StyledTypography>
          </StyledLink>
          <StyledLink to="/pricing">
            <StyledTypography>Pricing</StyledTypography>
          </StyledLink>
          <StyledLink to="/blog">
            <StyledTypography>Blog</StyledTypography>
          </StyledLink>
        </StyledMenu>
        <StyledButtons>
          <StyledButton to="https://app.appspector.com/login" variant="bordered">
            Sign In
          </StyledButton>
          <StyledButton to="/signup">Try for Free</StyledButton>
        </StyledButtons>
        <StyledHint>No credit card required</StyledHint>
      </StyledContainer>
    </StyledRoot>
  );
};

export default MobileMenu;
