import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { colors } from "src/theme/variables";
import { mediaSm, transition } from "src/theme/utils";
import { Typography, Svg, Link } from "src/theme/elements";

const StyledTypography = styled(Typography)``;

const getButtonVariant = variant => {
  switch (variant) {
    case "inverted":
      return css`
        background-color: ${colors.white};
        color: ${colors.crimson};
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);

        &:hover {
          background-color: ${colors.white};
          color: ${colors.crimson};
          box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.08);
        }

        &:active {
          background-color: ${colors.white};
          color: ${colors.crimson};
          box-shadow: none;
        }
      `;
    case "secondary":
      return css`
        padding: 8px 16px;
        border-radius: 6px;

        &:hover {
          transform: translateY(-2px);
        }

        ${mediaSm`
          padding: 7px 12px;
          border-radius: 5px;
        `}

        ${StyledTypography} {
          font-size: 16px;
          line-height: 26px;
        }
      `;
    case "tertiary":
      return css`
        padding: 8px 16px;
        border-radius: 6px;
        background-color: ${colors.lightGray};
        color: ${colors.blackish};
        box-shadow: none;

        &:hover {
          background-color: ${colors.silver};
          box-shadow: none;
        }

        &:active {
          background-color: ${colors.middleGray};
          box-shadow: none;
        }

        ${mediaSm`
          padding: 7px 12px;
          border-radius: 5px;
        `}

        ${StyledTypography} {
          font-size: 16px;
          line-height: 26px;
        }
      `;

    case "bordered":
      return css`
        background-color: ${colors.white};
        color: ${colors.crimson};
        box-shadow: inset 0 0 0 2px ${colors.crimson};

        &:hover {
          color: ${colors.white};
          box-shadow: none;
        }

        &:active {
          color: ${colors.white};
          box-shadow: none;
        }
      `;
    case "disabled":
      return css`
        cursor: not-allowed;
        background-color: ${colors.middleGray};
        color: ${colors.white};

        &:hover {
          background-color: ${colors.middleGray};
        }

        &:active {
          background-color: ${colors.middleGray};
        }
      `;
    default:
      return "";
  }
};

const StyledButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 0;
  padding: 16px 24px;
  border-radius: 8px;
  will-change: transform, opacity;
  transition: ${transition(["transform", "color", "background-color", "box-shadow"])};
  cursor: pointer;
  background-color: ${colors.crimson};
  color: ${colors.white};
  box-shadow: 0 4px 12px 0 rgba(249, 97, 123, 0.2);

  ${mediaSm`
    padding: 12px 20px;
  `}

  &:hover {
    background-color: ${colors.brightCrimson};
    transform: translateY(-3px);
    box-shadow: 0 12px 24px 0 rgba(249, 97, 123, 0.2);
  }

  &:active {
    background-color: ${colors.darkCrimson};
    transform: none;
    box-shadow: none;
  }

  ${StyledTypography} {
    flex: 1;
    text-align: center;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    color: inherit;

    ${mediaSm`
      font-size: 18px;
    `}
  }

  ${({ disabled, variant }) =>
    disabled
      ? css`
          cursor: not-allowed;
          background-color: ${colors.middleGray};
          color: ${colors.white};
          box-shadow: none;

          &:hover {
            background-color: ${colors.middleGray};
            transform: none;
            box-shadow: none;
          }

          &:active {
            background-color: ${colors.middleGray};
          }
        `
      : getButtonVariant(variant)}

  // Re-write global styles
  height: auto;
`;

const StyledIcon = styled.span`
  width: 24px;
  height: 24px;
  margin-right: 16px;

  ${mediaSm`
    width: 22px;
    height: 22px;
  `}

  svg,
  img {
    display: block;
    width: inherit;
    height: inherit;
  }
`;

const Button = props => {
  const { children, className, to, icon, variant, ...rest } = props;

  let content = <StyledTypography>{children}</StyledTypography>;

  if (icon !== undefined) {
    content = (
      <>
        <StyledIcon>
          <Svg path={`icons/${icon}`} />
        </StyledIcon>
        <StyledTypography>{children}</StyledTypography>
      </>
    );
  }

  const button = (
    <StyledButton variant={variant} icon={icon !== undefined} aria-label={children} {...rest}>
      {content}
    </StyledButton>
  );

  return to !== undefined ? (
    <Link className={className} to={to} {...rest}>
      {button}
    </Link>
  ) : (
    <span className={className}>{button}</span>
  );
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "inverted", "secondary", "tertiary", "bordered", "dense"]),
};

export default Button;
