import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { mediaSm } from "src/theme/utils";

const StyledRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;

  ${mediaSm`
    margin-left: -8px;
    margin-right: -8px;
  `}
`;

const StyledButton = styled.div`
  min-width: 194px;
  margin: 10px;

  ${mediaSm`
    margin: 8px;
  `}
`;

const Buttons = ({ children }) => (
  <StyledRoot>
    {children.map((button, index) => (
      <StyledButton key={index}>{button}</StyledButton>
    ))}
  </StyledRoot>
);

Buttons.propTypes = {
  children: PropTypes.node,
};

export default Buttons;
