import { createGlobalStyle } from "styled-components";
import { fonts, zIndex } from "src/theme/variables";
import theme from "src/components/theme";

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
  }

  svg {
    display: inline-block;
  }

  body {
    background-color: ${theme.title};
    color: ${theme.textColor};
    font-family: ${fonts.secondary}, Arial, sans-serif;
  }

  a {
    text-decoration: none;
    color: ${theme.titleColor};
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.titleColor};
    margin: 0;
  }

  h1 {
    font-size: 36px;
    line-height: 1.17;
    letter-spacing: 0.9px;
    font-weight: bold;
  }

  h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: 0.6px;
  }

  h5 {
    font-size: 18px;
    line-height: 1.33;
    color: ${theme.textColor};
    font-weight: normal;
  }

  button {
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 4px;
    height: 48px;
    color: ${theme.titleColor};
    font-weight: bold;
    font-size: 18px;
    line-height: 2.33;
    padding: 0;
  }

  div,
  section,
  footer,
  header {
    box-sizing: border-box;
  }

  .ReactModalPortal {
    position: relative;
    z-index: 2;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  #crisp-chatbox {
    z-index: ${zIndex.crispChatbox} !important;
  }

  #crisp-chatbox div > a {
    z-index: ${zIndex.crispButton} !important;
  }

  #crisp-chatbox div > a > span {
    background-color: ${theme.accentColor} !important;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
  }

  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${theme.colors.crimson};
    position: fixed;
    z-index: ${zIndex.nprogress};
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }

  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px ${theme.colors.crimson}, 0 0 5px ${theme.colors.crimson};
    opacity: 1.0;
    transform: rotate(3deg) translate(0px, -4px);
  }
`;

export default GlobalStyle;
