import { colors } from "src/theme/variables";

export default {
  backgroundColor: "#282831",
  lightBackgroundColor: "#2d2d38",
  titleColor: "#ffffff",
  textColor: "#cecece",
  accentColor: "#f9617b",
  dirtyPurple: "#6b4873",
  bodyColor: "#c0c0c5",
  blogTextColor: "#000000",
  blogMainBackgroundColor: "#f4f8fb",
  darkGreySidebarColor: "#333340",
  borderColor: "#4b4d5b",
  mobileBreakpoint: "600px",
  readableMaxWidth: "900px",
  demoBreakpoint: "1280px",
  topMenuBreakpoint: "700px",
  monitorsPageVerticalBreakpoint: "1000px",
  hiwVerticalBreakpoint: "1000px",
  monitorIconBorder: "#24242D",
  cardsBackground: "#202029",
  cardLightLineBackground: "#393a47",
  cardDarkLineBackground: "#343541",
  subtitleColor: "#738b94",
  darkText: "#191920",
  footerTitleText: "#333333",
  footerLinkText: "#666666",
  // New design
  colors,
};
